import { getTokenFromCookie } from '@/utils/cookies';
import { Router, scrollBehavior } from '@/utils/router.js';
import { hostBuild, setHtmlMeta } from '@/utils/utils';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "core_info" */ './home/home.vue'),
      meta: { desc: '首页' },
    },
    {
      path: '/special',
      name: 'Special',
      component: () => import(/* webpackChunkName: "core_info" */ './special/special.vue'),
      meta: { desc: '专题列表' },
    },
    {
      // params.id 专题ID
      path: '/special/big/:id',
      name: 'SpecialBig',
      component: () => import(/* webpackChunkName: "core_info" */ './special-big/special-big.vue'),
      meta: { desc: '专题详情大图模式' },
    },
    {
      // params.id 专题ID
      path: '/special/small/:id',
      name: 'SpecialSmall',
      component: () => import(/* webpackChunkName: "core_info" */ './special-small/special-small.vue'),
      meta: { desc: '专题详情小图模式' },
    },
    {
      // params.topic 板块短名（一级或二级）
      // query.offet 页码数
      // query.city 城市ID
      // query.sort 排序索引
      path: '/list/:topic',
      name: 'List',
      component: () => import(/* webpackChunkName: "core_info" */ './list/list.vue'),
      meta: { desc: '列表页' },
    },
    {
      // params.topic 板块短名（一级或二级）
      // query.offet 页码数
      // query.city 城市ID
      // query.sort 排序索引
      path: '/shoplist/:topic',
      name: 'ShopList',
      component: () => import(/* webpackChunkName: "core_info" */ './list/shoplist.vue'),
      meta: { desc: '店铺列表页' },
    },
    {
      // params.topic 板块短名（一级或二级）
      // query.offet 页码数
      // query.city 城市ID
      // query.sort 排序索引
      path: '/restaurantlist/:topic',
      name: 'RestaurantList',
      component: () => import(/* webpackChunkName: "core_info" */ './list/restaurantlist.vue'),
      meta: { desc: '饭店列表页' },
    },
    {
      path: '/detail/:topic/:id',
      name: 'Detail',
      component: () => import(/* webpackChunkName: "core_info" */ './detail/detail.vue'),
      meta: { desc: '详情页' },
    },
    {
      path: '/shop/:topic/:id',
      name: 'Shop',
      component: () => import(/* webpackChunkName: "core_info" */ './detail/shop.vue'),
      meta: { desc: '店铺' },
    },
    {
      path: '/restaurant/:topic/:id',
      name: 'Restaurant',
      component: () => import(/* webpackChunkName: "core_info" */ './detail/restaurant.vue'),
      meta: { desc: '饭店' },
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import(/* webpackChunkName: "core_info" */ './search/search.vue'),
      meta: { desc: '搜索页' },
    },
    {
      path: '/claim',
      name: 'Claim',
      component: () => import(/* webpackChunkName: "core_info" */ './claim/claim.vue'),
      meta: { desc: '认领页' },
    },
    {
      path: '/publish',
      name: 'Publish',
      component: () => import(/* webpackChunkName: "core_info" */ './publish/publish.vue'),
      meta: { desc: '发布', isAuth: true },
    },
    {
      path: '/success/:id',
      name: 'Success',
      component: () => import(/* webpackChunkName: "core_info" */ './success/success.vue'),
      meta: { desc: '发布成功' },
    },
    {
      path:'/rank/type/:type',
      name:'InfoRankCategory',
      component: () => import(/* webpackChunkName: "core_info" */ './rank/rankCategory.vue'),
    },
  
    {
      path:'/rank/info/:name',
      name:'RankInfos',
      component: () => import(/* webpackChunkName: "core_info" */ './rank/rankInfos.vue'),
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_info" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_info" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // 这里只能用本地token进行判断是否登录，否则刷新页面会出现问题
  const token = getTokenFromCookie();
  if (to.meta.isAuth === true && !token) {
    location.replace(hostBuild(`/login?redirect=${location.href}`, 'account'));
    return next(false);
  }

  if (to.name == 'Home') {
    setHtmlMeta({
      title: '多伦多黄页 - 约克论坛 - 加拿大第一中文网',
      keywords: '多伦多搬家接送,多伦多美食,多伦多装修,教育培训,房屋维修,法律援助,求职招聘,yellowpage,多伦多休闲娱乐,多伦多农场',
      description:
        '加拿大多伦多黄页 yorkbbs yellowpage，为华人和留学生提供搬家接送、房屋装修维修、教育培训、工作招聘、法律援助、华人商会、美食饭店、常用电话等生活服务和中文黄页的信息。yorkbbs.ca',
    });
  } else if (to.name == 'Publish') {
    setHtmlMeta({
      title: '发布信息 - 多伦多黄页 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'Search') {
    setHtmlMeta({
      title: '搜索 - 多伦多黄页 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if(to.name == 'Special'){
    setHtmlMeta({
      title:'生活资讯 - 多伦多黄页 - 约克论坛',
      keywords:`多伦多攻略，多伦多榜单，多伦多美食，采访专栏，多伦多探店，多伦多商家推荐，多伦多本地服务推荐`,
      description:'约克论坛精心打造多伦多必备生活服务信息Top榜单，提供多伦多吃喝玩乐、周末好去处、买房买车、移民福利、丽人服务等最新资讯信息。yorkbbs.ca'
    })
  } else if (to.name == 'WebSafe') {
    setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'NotFound') {
    setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
  } else {
    setHtmlMeta({ title: '', keywords: '', description: '' });
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });

  next();
});

export { router };
